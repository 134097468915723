var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericAutoComplete from "../../../components/GenericFormFields/GenericAutoComplete";
import GenericControllerTextInput from "../../../components/GenericFormFields/TextInput";
export default function UTMFields(_a) {
    var utmType = _a.utmType, utmObjectiveOptions = _a.utmObjectiveOptions, utmObjectives = _a.utmObjectives, utmSourceOptions = _a.utmSourceOptions, utmSources = _a.utmSources, utmMediumOptions = _a.utmMediumOptions, utmMediums = _a.utmMediums, utmContentOptions = _a.utmContentOptions, utmContents = _a.utmContents, utmCampaign = _a.utmCampaign, errors = _a.errors, control = _a.control;
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericAutoComplete, { name: "utm_objectives", label: "UTM Objectives", options: utmObjectiveOptions[utmType].map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: utmObjectives, placeholder: "Select UTM Objectives", rules: {
                    required: false,
                }, error: "utm_objectives" in errors, limitTags: 3 })),
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericAutoComplete, { name: "utm_sources", label: "UTM Sources", options: utmSourceOptions[utmType].map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: utmSources, placeholder: "Select UTM Sources", rules: {
                    required: false,
                }, error: "utm_sources" in errors, limitTags: 3 })),
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericAutoComplete, { name: "utm_mediums", label: "UTM Mediums", options: utmMediumOptions[utmType].map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: utmMediums, placeholder: "Select UTM Medium", rules: {
                    required: false,
                }, error: "utm_mediums" in errors, limitTags: 3 })),
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericAutoComplete, { name: "utm_contents", label: "UTM Contents", options: utmContentOptions[utmType].map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: utmContents, placeholder: "Select UTM Contents", rules: {
                    required: false,
                }, error: "utm_contents" in errors, limitTags: 3 })),
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericControllerTextInput, { label: "UTM Campaign", type: "text", control: control, rules: { required: false }, name: "utm_campaign", error: "utm_campaign" in errors, defaultValue: utmCampaign }))));
}
