import React, { useEffect, useState } from "react";
import { Button, TextField, Typography, Modal, Dialog, DialogContent, Alert, AlertTitle, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { isEmpty } from "lodash";
import { useForm, Controller } from "react-hook-form";
import { router } from "@inertiajs/react";
import Layout from "../../Layout";
import Display from "../Display";
// import ReportsNav from "./components/ReportsNav";
import GenericControllerTextInput from "../../../components/GenericFormFields/TextInput";
import GenericAutoComplete from "../../../components/GenericFormFields/GenericAutoComplete";
import ReportDateSelect from "../components/ReportDateSelect";
import UTMFields from "../components/UTMFields";
import { getFormData } from "../../../utils/reportUtils";
export default function DonationReportForm(_a) {
    var stateOptions = _a.states, utmContentOptions = _a.utm_contents, utmObjectiveOptions = _a.utm_objectives, utmMediumOptions = _a.utm_mediums, utmSourceOptions = _a.utm_sources, serverErrors = _a.errors, csrf = _a.csrf, summary = _a.summary, form_data = _a.form_data;
    var _b = useState(false), isSubmitting = _b[0], setIsSubmitting = _b[1];
    var handleCloseModal = function () {
        setIsSubmitting(false);
    };
    var reportForm = getFormData(form_data);
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    var _d = useState(true), showMenu = _d[0], toggleShowMenu = _d[1];
    var _e = useState(false), showErrors = _e[0], setShowErrors = _e[1];
    var _f = useForm(), control = _f.control, handleSubmit = _f.handleSubmit, errors = _f.formState.errors, reset = _f.reset;
    var utmCampaign = reportForm.utm_campaign;
    var findById = reportForm.find_by_id;
    var states = reportForm.states;
    var utmContents = reportForm.utm_contents;
    var utmObjectives = reportForm.utm_objectives;
    // const utmCampaigns = reportForm.utm_campaigns;
    var utmMediums = reportForm.utm_mediums;
    var utmSources = reportForm.utm_sources;
    var minDonationAmount = reportForm.min_donation_amount;
    var maxDonationAmount = reportForm.max_donation_amount;
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var onSubmit = function (data) {
        data.report_options = [
            { states: stateOptions },
            { utm_contents: utmContentOptions },
            { utm_objectives: utmObjectiveOptions },
            { utm_mediums: utmMediumOptions },
            { utm_sources: utmSourceOptions },
        ];
        data.export_name = "donations";
        data.export_type = ".csv";
        router.post("/report/donations", data);
    };
    useEffect(function () {
        if ((summary === null || summary === void 0 ? void 0 : summary.total) > 0) {
            toggleShowMenu(!showMenu);
        }
        setShowErrors((summary === null || summary === void 0 ? void 0 : summary.total) === 0);
    }, [summary]);
    useEffect(function () {
        if (!isEmpty(serverErrors)) {
            setIsSubmitting(false);
        }
    }, [serverErrors]);
    return (React.createElement(Layout, { title: "Report", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        showMenu && (React.createElement(Grid, { container: true, spacing: 3 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h2" }, "Donation Reports"),
                React.createElement(Controller, { control: control, name: "report", defaultValue: "donations", render: function (_a) {
                        var field = _a.field;
                        return (React.createElement(TextField, { hidden: true, error: "report" in errors }));
                    } }),
                React.createElement(Button, { type: "button", onClick: function () {
                        reset();
                    }, color: "secondary", variant: "outlined" }, "Reset Form")),
            showErrors && (React.createElement(Grid, { xs: 12 },
                React.createElement(Alert, { severity: "warning" },
                    React.createElement(AlertTitle, null, "No Result"),
                    "Your search returned without any results."))),
            React.createElement(Grid, { xs: 6, md: 3 },
                React.createElement(GenericControllerTextInput, { label: "Min Donation Amount", type: "number", control: control, rules: { required: false }, name: "min_donation_amount", error: "min_donation_amount" in errors, defaultValue: minDonationAmount })),
            React.createElement(Grid, { xs: 6, md: 3 },
                React.createElement(GenericControllerTextInput, { label: "Max Donation Amount", type: "number", control: control, rules: { required: false }, name: "max_donation_amount", error: "max_donation_amount" in errors, defaultValue: maxDonationAmount })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericAutoComplete, { name: "states", label: "States", options: stateOptions, control: control, value: states, placeholder: "Select State", rules: {
                        required: false,
                    }, error: "states" in errors, limitTags: 3 })),
            React.createElement(UTMFields, { utmType: "donations", utmObjectiveOptions: utmObjectiveOptions, utmObjectives: utmObjectives, utmSourceOptions: utmSourceOptions, utmSources: utmSources, utmMediumOptions: utmMediumOptions, utmMediums: utmMediums, utmContentOptions: utmContentOptions, utmContents: utmContents, utmCampaign: utmCampaign, errors: errors, control: control }),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { control: control, label: "Find By ID", multiline: true, type: "text", rules: { required: false }, name: "find_by_id", error: "find_by_id" in errors, defaultValue: findById, minRows: 1 })),
            React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "Donation Date", startLabel: "Start Date", endLabel: "End Date", startName: "start_date", endName: "end_date", startControl: control, endControl: control, required: true }),
            React.createElement(Grid, { container: true, xs: 12, justifyContent: "center" },
                React.createElement(Grid, { xs: 3 },
                    React.createElement(Button, { type: "button", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true }, "Run Report"))))),
        !showMenu && (React.createElement(Display, { csrf: csrf, summary: summary, formData: form_data, handleToggleMenu: function () {
                toggleShowMenu(!showMenu);
            } })),
        React.createElement(Modal, { open: isSubmitting, onClose: handleCloseModal },
            React.createElement(Dialog, { open: isSubmitting, onClose: handleCloseModal },
                React.createElement(DialogContent, { style: { minWidth: "500px", textAlign: "center" } }, "This form is being submitted")))));
}
