import React from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import ReportDateSelect from "../components/ReportDateSelect";
import { labelAgentOptions } from "../../../utils/reportUtils";
import GenericAutoComplete from "../../../components/GenericFormFields/GenericAutoComplete";
import GenericControllerSelectCheckbox from "../../../components/GenericFormFields/SelectCheckbox";
export default function FilterByFields(_a) {
    var filterBy = _a.filterBy, agentOptions = _a.agentOptions, control = _a.control, agents = _a.agents, getValues = _a.getValues, errors = _a.errors, states = _a.states, stateOptions = _a.stateOptions, reportForm = _a.reportForm, filterOptions = _a.filterOptions;
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericControllerSelectCheckbox, { name: "filter_by", label: "Filter By", options: filterOptions, value: filterBy, control: control })),
        filterBy.includes("agents") && (React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericAutoComplete, { name: "agents", label: "Agents", options: labelAgentOptions(agentOptions), control: control, value: agents, placeholder: "Select Agent", rules: {
                    required: true,
                    validate: function () { return getValues("agents").length > 0; },
                }, error: "agents" in errors, limitTags: 3 }))),
        filterBy.includes("states") && (React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericAutoComplete, { name: "states", label: "States", options: stateOptions, control: control, value: states, placeholder: "Select State", rules: {
                    required: true,
                    validate: function () { return getValues("states").length > 0; },
                }, error: "states" in errors, limitTags: 3 }))),
        filterBy.includes("dob") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "Member Birthday", startLabel: "Start Date", endLabel: "End Date", startName: "dob_start_date", endName: "dob_end_date", startControl: control, endControl: control, required: true })),
        filterBy.includes("member_since") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "Member Since", startLabel: "Start Date", endLabel: "End Date", startName: "since_start_date", endName: "since_end_date", startControl: control, endControl: control, required: true })),
        filterBy.includes("membership_cancellation") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "Member Cancellation", startLabel: "Start Date", endLabel: "End Date", startName: "cancel_start_date", endName: "cancel_end_date", startControl: control, endControl: control, required: true })),
        filterBy.includes("membership_expiration") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "Member Expiration", startLabel: "Start Date", endLabel: "End Date", startName: "exp_start_date", endName: "exp_end_date", startControl: control, endControl: control, required: true })),
        filterBy.includes("membership_prev_expiration") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "Member Previous Expiration", startLabel: "Start Date", endLabel: "End Date", startName: "prev_exp_start_date", endName: "prev_exp_end_date", startControl: control, endControl: control, required: true })),
        filterBy.includes("send_cards") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "Member Send Cards", startLabel: "Start Date", endLabel: "End Date", startName: "card_start_date", endName: "card_end_date", startControl: control, endControl: control, required: true })),
        filterBy.includes("created_date") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "Member Created Date", startLabel: "Start Date", endLabel: "End Date", startName: "start_date", endName: "end_date", startControl: control, endControl: control, required: true }))));
}
