import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { usePage } from "@inertiajs/react";
import Layout from "../Layout";
import { hasPermission } from "../../utils/utils";
import GenericIcon from "../../components/GenericIcon";
import { reportTypes } from "../../utils/reportUtils";
import { router } from "@inertiajs/react";
export default function ReportSelect() {
    var auth = usePage().props.auth;
    var _a = useState(true), menuOpen = _a[0], toggleMenuOpen = _a[1];
    return (React.createElement(Layout, { title: "Select a Report", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { spacing: 0, xs: 6, container: true }, reportTypes.map(function (reportType) {
            if (!hasPermission(auth, reportType.access)) {
                return null;
            }
            return (React.createElement(Grid, { key: reportType.id },
                React.createElement(GenericIcon, { id: reportType.id, title: reportType.name, icon: reportType.icon, handleClick: function () {
                        router.get("/report/" + reportType.id);
                    } })));
        }))));
}
