import React, { useEffect, useState } from "react";
import { Button, TextField, Typography, Modal, Dialog, DialogContent, Alert, AlertTitle, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { isEmpty } from "lodash";
import { useForm, Controller } from "react-hook-form";
import { router } from "@inertiajs/react";
import Layout from "../../Layout";
import Display from "../../Reports/Display";
// import ReportsNav from "../components/ReportsNav";
import GenericControllerTextInput from "../../../components/GenericFormFields/TextInput";
import GenericControllerSelectCheckbox from "../../../components/GenericFormFields/SelectCheckbox";
import GenericAutoComplete from "../../../components/GenericFormFields/GenericAutoComplete";
import ReportDateSelect from "../components/ReportDateSelect";
import UTMFields from "../components/UTMFields";
import { getFormData, labelAgentOptions, policyStatuses, userTypes, } from "../../../utils/reportUtils";
export default function PolicyReportForm(_a) {
    var agentOptions = _a.agents, leadOptions = _a.lead_types, insuranceTypeOptions = _a.insurance_types, locationOptions = _a.locations, utmContentOptions = _a.utm_contents, utmObjectiveOptions = _a.utm_objectives, utmMediumOptions = _a.utm_mediums, utmSourceOptions = _a.utm_sources, dispositionOptions = _a.dispositions, serverErrors = _a.errors, csrf = _a.csrf, summary = _a.summary, form_data = _a.form_data;
    var _b = useState(false), isSubmitting = _b[0], setIsSubmitting = _b[1];
    var handleCloseModal = function () {
        setIsSubmitting(false);
    };
    var reportForm = getFormData(form_data);
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    var _d = useState(true), showMenu = _d[0], toggleShowMenu = _d[1];
    var _e = useState(false), showErrors = _e[0], setShowErrors = _e[1];
    var _f = useForm(), control = _f.control, handleSubmit = _f.handleSubmit, errors = _f.formState.errors, reset = _f.reset;
    var utmCampaign = reportForm.utm_campaign;
    var findById = reportForm.find_by_id;
    var policyStatus = reportForm.policyStatus;
    var created_by = reportForm.created_by;
    var transferred_to = reportForm.transferred_to;
    var utmContents = reportForm.utm_contents;
    var utmObjectives = reportForm.utm_objectives;
    // const utmCampaigns = reportForm.utm_campaigns;
    var utmMediums = reportForm.utm_mediums;
    var utmSources = reportForm.utm_sources;
    var lead_types = reportForm.lead_types;
    var insurance_types = reportForm.insurance_types;
    var user_types = reportForm.user_types;
    var locations = reportForm.locations;
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var onSubmit = function (data) {
        data.report_options = [
            { agents: agentOptions },
            { lead_types: leadOptions },
            { insurance_types: insuranceTypeOptions },
            { locations: locationOptions },
            { utm_contents: utmContentOptions },
            { utm_objectives: utmObjectiveOptions },
            { utm_mediums: utmMediumOptions },
            { utm_sources: utmSourceOptions },
            { dispositions: dispositionOptions },
        ];
        data.export_name = "policies";
        data.export_type = ".csv";
        router.post("/report/policies", data);
    };
    useEffect(function () {
        if ((summary === null || summary === void 0 ? void 0 : summary.total) > 0) {
            toggleShowMenu(!showMenu);
        }
        setShowErrors((summary === null || summary === void 0 ? void 0 : summary.total) === 0);
    }, [summary]);
    useEffect(function () {
        if (!isEmpty(serverErrors)) {
            setIsSubmitting(false);
        }
    }, [serverErrors]);
    return (React.createElement(Layout, { title: "Report", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        showMenu && (React.createElement(Grid, { container: true, spacing: 3 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h2" }, "Policy Reports"),
                React.createElement(Controller, { control: control, name: "report", defaultValue: "policies", render: function (_a) {
                        var field = _a.field;
                        return (React.createElement(TextField, { hidden: true, error: "report" in errors }));
                    } }),
                React.createElement(Button, { type: "button", onClick: function () {
                        reset();
                    }, color: "secondary", variant: "outlined" }, "Reset Form")),
            showErrors && (React.createElement(Grid, { xs: 12 },
                React.createElement(Alert, { severity: "warning" },
                    React.createElement(AlertTitle, null, "No Result"),
                    "Your search returned without any results."))),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerSelectCheckbox, { name: "lead_types", control: control, label: "Lead Types", options: leadOptions, rules: {
                        required: false,
                    }, error: "lead_types" in errors, value: lead_types })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericAutoComplete, { name: "transferred_to", label: "Transferred To", options: labelAgentOptions(agentOptions), control: control, value: transferred_to, placeholder: "Select Agent", rules: {
                        required: false,
                    }, error: "transferred_to" in errors, limitTags: 3 })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerSelectCheckbox, { name: "insurance_types", control: control, label: "Insurance Types", options: insuranceTypeOptions, rules: {
                        required: false,
                    }, error: "insurance_types" in errors, value: insurance_types })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericAutoComplete, { name: "user_types", label: "Agent Type", options: userTypes, control: control, value: user_types, placeholder: "Select Agent Type", rules: {
                        required: false,
                    }, error: "user_types" in errors, limitTags: 3 })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericAutoComplete, { name: "created_by", label: "Created By", options: labelAgentOptions(agentOptions), control: control, value: created_by, placeholder: "Select Agent", rules: {
                        required: false,
                    }, error: "created_by" in errors, limitTags: 3 })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerSelectCheckbox, { name: "locations", label: "Locations", options: locationOptions, control: control, value: locations, rules: {
                        required: false,
                    }, error: "locations" in errors })),
            React.createElement(UTMFields, { utmType: "leads", utmObjectiveOptions: utmObjectiveOptions, utmObjectives: utmObjectives, utmSourceOptions: utmSourceOptions, utmSources: utmSources, utmMediumOptions: utmMediumOptions, utmMediums: utmMediums, utmContentOptions: utmContentOptions, utmContents: utmContents, utmCampaign: utmCampaign, errors: errors, control: control }),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { control: control, label: "Find By ID", multiline: true, type: "text", rules: { required: false }, name: "find_by_id", error: "find_by_id" in errors, defaultValue: findById, minRows: 1 })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerSelectCheckbox, { name: "policyStatus", control: control, label: "Status", options: policyStatuses, rules: {
                        required: false,
                    }, error: "policyStatus" in errors, value: policyStatus })),
            React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "Policy Create Date", startLabel: "Start Date", endLabel: "End Date", startName: "start_date", endName: "end_date", startControl: control, endControl: control, required: true }),
            React.createElement(Grid, { container: true, xs: 12, justifyContent: "center" },
                React.createElement(Grid, { xs: 3 },
                    React.createElement(Button, { type: "button", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true }, "Run Report"))))),
        !showMenu && (React.createElement(Display, { csrf: csrf, summary: summary, formData: form_data, handleToggleMenu: function () {
                toggleShowMenu(!showMenu);
            } })),
        React.createElement(Modal, { open: isSubmitting, onClose: handleCloseModal },
            React.createElement(Dialog, { open: isSubmitting, onClose: handleCloseModal },
                React.createElement(DialogContent, { style: { minWidth: "500px", textAlign: "center" } }, "This form is being submitted")))));
}
