import React from "react";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import BarChartDaily from "../../components/charts/BarChartDaily";
import { startCase } from "lodash";
import MemberReportTable from "../ReportsOld/components/MemberReportTable";
import OrderReportTable from "../ReportsOld/components/OrderReportTable";
import LeadReportTable from "../ReportsOld/components/LeadReportTable";
import HealthReportTable from "../ReportsOld/components/HealthReportTable";
import LifeReportTable from "../ReportsOld/components/LifeReportTable";
import PolicyReportTable from "../ReportsOld/components/PolicyReportTable";
import NumberFormat from "react-number-format";
import DonationReportTable from "../ReportsOld/components/DonationReportTable";
import axios from "axios";
import { format_date } from "../../utils/dateUtils";
export default function Display(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var csrf = _a.csrf, summary = _a.summary, formData = _a.formData, handleToggleMenu = _a.handleToggleMenu;
    var colors = [
        "#ed1b2e",
        "#004b79",
        "#ecb731",
        "#537b35",
        "#371777",
        "#56a0d3",
        "#7f181b",
        "#e4e932",
        "#be0027",
        "#8ec06c",
        "#cf8d2e",
        "#0091cd",
        "#2c9f45",
        "#d7d7d8",
        "#c4dff6",
    ];
    var labels = summary.default_labels.map(function (el) { return startCase(el); });
    var graphData = Object.create({});
    for (var _i = 0, _k = summary === null || summary === void 0 ? void 0 : summary.default_labels; _i < _k.length; _i++) {
        var key = _k[_i];
        for (var _l = 0, _m = summary === null || summary === void 0 ? void 0 : summary.default_sub_labels; _l < _m.length; _l++) {
            var label = _m[_l];
            if (Object.keys(graphData).includes(label)) {
                graphData[label].push((_e = (_d = (_c = (_b = summary === null || summary === void 0 ? void 0 : summary.default[key]) === null || _b === void 0 ? void 0 : _b[label]) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.total) !== null && _e !== void 0 ? _e : 0);
            }
            else {
                graphData[label] = [
                    (_j = (_h = (_g = (_f = summary === null || summary === void 0 ? void 0 : summary.default[key]) === null || _f === void 0 ? void 0 : _f[label]) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.total) !== null && _j !== void 0 ? _j : 0,
                ];
            }
        }
    }
    var datasets = Array();
    var colorCount = 0;
    for (var label in graphData) {
        datasets.push({
            label: startCase(label),
            data: graphData[label],
            backgroundColor: colors[colorCount],
        });
        colorCount += 1;
        if (colorCount > colors.length - 1) {
            colorCount = 0;
        }
    }
    var dataBar = {
        labels: labels,
        datasets: datasets,
    };
    var download = function () {
        axios
            .post("/report/export-report", {
            total_rows: summary === null || summary === void 0 ? void 0 : summary.total,
            report_name: formData.export_name,
            report_type: formData.export_type,
            formData: JSON.stringify(formData),
            _token: csrf,
        }, { responseType: "blob" })
            .then(function (response) {
            var _a;
            var url = window.URL.createObjectURL(new Blob([response.data]));
            var link = document.createElement("a");
            link.href = url;
            var today = format_date(new Date());
            var fileName = formData.export_name + "_" + today + ".csv";
            link.setAttribute("download", fileName);
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            (_a = link === null || link === void 0 ? void 0 : link.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(link);
        })
            .catch(function () {
            console.error("Error exporting.");
        });
    };
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
        React.createElement(Grid, { container: true, xs: 12, md: 6 },
            React.createElement(Typography, { variant: "h5", component: "h2" },
                startCase(formData.export_name),
                " Reports")),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(Button, { variant: "outlined", color: "secondary", onClick: handleToggleMenu, fullWidth: true }, "Show Form")),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(Button, { type: "button", variant: "outlined", color: "primary", onClick: function () { return download(); }, fullWidth: true }, "Download Report")),
        React.createElement(Grid, { container: true, xs: 12, justifyContent: "center" },
            React.createElement(Typography, { variant: "h5", component: "h3" },
                React.createElement(NumberFormat, { value: summary === null || summary === void 0 ? void 0 : summary.total, displayType: "text", thousandSeparator: true }), " Total")),
        React.createElement(Grid, { container: true, xs: 12, justifyContent: "center" },
            React.createElement(Grid, { xs: 12, xl: 6 },
                React.createElement(Paper, null,
                    React.createElement(BarChartDaily, { data: dataBar, title: summary.default_name })))),
        ["members"].includes(formData.export_name) && (React.createElement(Grid, { xs: 12 },
            React.createElement(MemberReportTable, { data: summary === null || summary === void 0 ? void 0 : summary.data, groupByName: summary === null || summary === void 0 ? void 0 : summary.default_group_name, mainGroupByName: summary === null || summary === void 0 ? void 0 : summary.default_main_group_name }))),
        ["orders"].includes(formData.export_name) && (React.createElement(Grid, { xs: 12 },
            React.createElement(OrderReportTable, { data: summary === null || summary === void 0 ? void 0 : summary.data, groupByName: summary === null || summary === void 0 ? void 0 : summary.default_group_name, mainGroupByName: summary === null || summary === void 0 ? void 0 : summary.default_main_group_name }))),
        ["leads"].includes(formData.export_name) && (React.createElement(Grid, { xs: 12 },
            React.createElement(LeadReportTable, { data: summary === null || summary === void 0 ? void 0 : summary.data }))),
        ["health_applications"].includes(formData.export_name) && (React.createElement(Grid, { xs: 12 },
            React.createElement(HealthReportTable, { data: summary === null || summary === void 0 ? void 0 : summary.data }))),
        ["life_applications"].includes(formData.export_name) && (React.createElement(Grid, { xs: 12 },
            React.createElement(LifeReportTable, { data: summary === null || summary === void 0 ? void 0 : summary.data }))),
        ["policies"].includes(formData.export_name) && (React.createElement(Grid, { xs: 12 },
            React.createElement(PolicyReportTable, { data: summary === null || summary === void 0 ? void 0 : summary.data }))),
        ["donations"].includes(formData.export_name) && (React.createElement(Grid, { xs: 12 },
            React.createElement(DonationReportTable, { data: summary === null || summary === void 0 ? void 0 : summary.data })))));
}
