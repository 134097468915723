import React from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import { Box, Typography } from "@mui/material";
export var BrokerDatesCard = function (_a) {
    var broker = _a.broker;
    var rows = [
        ["E&O Received", "eo_rec"],
        ["E&O Effective", "eo_eff"],
        ["E&O Expiration", "eo_exp"],
        ["Agent Contract", "agent_contract"],
        ["w9", "w9"],
        ["Direct Deposit Form", "direct_deposit"],
        ["Voided Check", "voided_check"],
        ["OIG Background Check", "bg_check_oig"],
        ["OIG Background Check Expiration", "bg_check_gsa"],
        ["GSA Background Check", "bg_check_oig_exp"],
        ["GSA Background Check Expiration", "bg_check_gsa_exp"],
        ["Driver's License", "drivers_license"],
        ["Social Security Card", "ss_card"],
        ["License(s)", "licenses"],
        ["AHIP Certificate", "ahip"],
        ["FWA Certificate", "fwa"],
        ["FFM Certificate", "ffm"],
        ["All Carrier Certificates", "all_carrier"],
    ];
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { xs: 12, style: {
                backgroundColor: "#F1F1F1",
                padding: "6px",
                marginBottom: "14px",
            } },
            React.createElement(Typography, { variant: "h6" }, "Broker Document Dates")),
        rows.map(function (row) {
            var _a;
            return (React.createElement(Grid, { container: true, xs: 12, key: "broker_".concat(row[1]) },
                React.createElement(Grid, { xs: 6 },
                    React.createElement(Box, null,
                        React.createElement(Typography, { variant: "body2" },
                            row[0],
                            ":"))),
                React.createElement(Grid, { xs: 6 },
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_a = broker["".concat(row[1])]) !== null && _a !== void 0 ? _a : "-"))));
        })));
};
