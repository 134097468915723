import React from "react";
import { Button, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { usePage } from "@inertiajs/react";
import { hasPermission } from "../../../utils/utils";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(0.4),
            },
        },
    });
});
var ReportsNav = function (_a) {
    var reportTypes = _a.reportTypes, report = _a.report, handleClick = _a.handleClick;
    var auth = usePage().props.auth;
    var classes = useStyles();
    return (React.createElement(Box, { mb: 2, className: classes.root }, reportTypes.map(function (el) {
        if (!hasPermission(auth, el.access)) {
            return null;
        }
        return (React.createElement(Button, { key: el.id, id: el.id, onClick: function () { return handleClick(el.id); }, variant: "contained", color: report === el.id ? "primary" : "inherit" }, el.name));
    })));
};
export default ReportsNav;
