import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import { Typography } from "@mui/material";
import Layout from "../../Layout";
var CampaignsReport = function (_a) {
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    return (React.createElement(Layout, { title: "Campaigns Report", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { xs: 9 },
            React.createElement(Typography, { variant: "h5", component: "h2" }, "Online Ads Campaigns")),
        React.createElement(Grid, { spacing: 0, xs: 12, container: true },
            React.createElement("div", { dangerouslySetInnerHTML: {
                    __html: '<iframe width="1400" height="700"  frameborder="no" src="https://infrastructure-api.amac.us/infrastructure/api/distAMAC/pages/admin/medicare.php?token=WTLJT0xP3lpWLb4HYymZUMJFCtKfkleD4yP0MiFGArq3Ffc4QtqMGGIlHACBDZES" />',
                } }))));
};
export default CampaignsReport;
