import React, { useEffect, useState } from "react";
import { Button, TextField, Typography, Modal, Dialog, DialogContent, Alert, AlertTitle, Checkbox, FormControlLabel, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { hasPermission } from "../../../utils/utils";
import { isEmpty } from "lodash";
import { useForm, Controller } from "react-hook-form";
import { router, usePage } from "@inertiajs/react";
import Layout from "../../Layout";
// import ReportsNav from "./../components/ReportsNav";
import GenericControllerTextInput from "../../../components/GenericFormFields/TextInput";
import GenericControllerSelectCheckbox from "../../../components/GenericFormFields/SelectCheckbox";
import GenericControllerCheckboxInput from "../../../components/GenericFormFields/CheckboxInput";
import { filterOptions, getFormData, statuses, } from "../../../utils/reportUtils";
import Display from "../Display";
import FilterByFields from "../components/FilterByFields";
import UTMFields from "../components/UTMFields";
export default function MemberReportForm(_a) {
    var stateOptions = _a.states, agentOptions = _a.agents, utmCampaignOptions = _a.utm_campaigns, utmContentOptions = _a.utm_contents, utmObjectiveOptions = _a.utm_objectives, utmMediumOptions = _a.utm_mediums, utmSourceOptions = _a.utm_sources, serverErrors = _a.errors, csrf = _a.csrf, summary = _a.summary, form_data = _a.form_data;
    var auth = usePage().props.auth;
    var _b = useState(false), isSubmitting = _b[0], setIsSubmitting = _b[1];
    var _c = useState(false), donationChecked = _c[0], setDonationChecked = _c[1];
    var handleCloseModal = function () {
        setIsSubmitting(false);
    };
    var reportForm = getFormData(form_data);
    var _d = useState(true), menuOpen = _d[0], toggleMenuOpen = _d[1];
    var _e = useState(true), showMenu = _e[0], toggleShowMenu = _e[1];
    var _f = useState(false), showErrors = _f[0], setShowErrors = _f[1];
    var _g = useForm(), control = _g.control, handleSubmit = _g.handleSubmit, errors = _g.formState.errors, getValues = _g.getValues, setValue = _g.setValue, watch = _g.watch, reset = _g.reset;
    var utmCampaign = reportForm.utm_campaign;
    var findById = reportForm.find_by_id;
    var findByZip = reportForm.find_by_zip;
    var findByCongressionalDistrict = reportForm.find_by_congressional_district;
    var status = reportForm.status;
    var states = reportForm.states;
    var agents = reportForm.agents;
    var utmContents = reportForm.utm_contents;
    var utmObjectives = reportForm.utm_objectives;
    var utmMediums = reportForm.utm_mediums;
    var utmSources = reportForm.utm_sources;
    var filterBy = watch("filter_by", reportForm.filter_by);
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var onSubmit = function (data) {
        data.report_options = [
            { states: stateOptions },
            { agents: agentOptions },
            { utm_campaigns: utmCampaignOptions },
            { utm_contents: utmContentOptions },
            { utm_objectives: utmObjectiveOptions },
            { utm_mediums: utmMediumOptions },
            { utm_sources: utmSourceOptions },
        ];
        data.export_name = "members";
        data.export_type = ".csv";
        router.post("/report/members", data);
    };
    useEffect(function () {
        if ((summary === null || summary === void 0 ? void 0 : summary.total) > 0) {
            toggleShowMenu(!showMenu);
        }
        setShowErrors((summary === null || summary === void 0 ? void 0 : summary.total) === 0); // previously tried to set this to false... needs some investigation
    }, [summary]);
    useEffect(function () {
        if (!isEmpty(serverErrors)) {
            setIsSubmitting(false);
        }
    }, [serverErrors]);
    var toggleHasDonation = function () {
        var next_has_donation_value = !donationChecked;
        setDonationChecked(next_has_donation_value);
        next_has_donation_value === true
            ? setValue("unmarketable_status", next_has_donation_value)
            : null;
    };
    return (React.createElement(Layout, { title: "Members Report", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        showMenu && (React.createElement(Grid, { container: true, spacing: 3 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h2" }, "Member Reports"),
                React.createElement(Controller, { control: control, name: "report", defaultValue: "members", render: function (_a) {
                        var field = _a.field;
                        return (React.createElement(TextField, { hidden: true, error: "report" in errors }));
                    } }),
                React.createElement(Button, { type: "button", onClick: function () {
                        reset();
                    }, color: "secondary", variant: "outlined" }, "Reset Form")),
            showErrors && (React.createElement(Grid, { xs: 12 },
                React.createElement(Alert, { severity: "warning" },
                    React.createElement(AlertTitle, null, "No Result"),
                    "Your search returned without any results."))),
            React.createElement(UTMFields, { utmType: "members", utmObjectiveOptions: utmObjectiveOptions, utmObjectives: utmObjectives, utmSourceOptions: utmSourceOptions, utmSources: utmSources, utmMediumOptions: utmMediumOptions, utmMediums: utmMediums, utmContentOptions: utmContentOptions, utmContents: utmContents, utmCampaign: utmCampaign, errors: errors, control: control }),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { control: control, label: "Find By ID", multiline: true, type: "text", rules: { required: false }, name: "find_by_id", error: "find_by_id" in errors, defaultValue: findById, minRows: 1 })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { control: control, label: "Find By Congressional District", multiline: true, type: "text", rules: { required: false }, name: "find_by_congressional_district", error: "find_by_congressional_district" in errors, defaultValue: findByCongressionalDistrict, minRows: 1 })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { control: control, label: "Find By Zip", multiline: true, type: "text", rules: { required: false }, name: "find_by_zip", error: "find_by_zip" in errors, defaultValue: findByZip, minRows: 1 })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerSelectCheckbox, { name: "status", control: control, label: "Status", options: statuses(auth), rules: {
                        required: false,
                    }, error: "status" in errors, value: status })),
            React.createElement(FilterByFields, { filterBy: filterBy, agentOptions: agentOptions, control: control, agents: agents, getValues: getValues, errors: errors, states: states, stateOptions: stateOptions, reportForm: reportForm, filterOptions: filterOptions }),
            React.createElement(Grid, { xs: 6, md: 3 },
                React.createElement(GenericControllerCheckboxInput, { name: "show_latest_order", control: control, defaultChecked: false, label: "Include Latest Order Date (For reports less than 2 years)", color: "primary" })),
            hasPermission(auth, "run unmarketable_member reports") ? (React.createElement(React.Fragment, null,
                React.createElement(FormControlLabel, { label: "Show AMAC Action Donors only", control: React.createElement(Controller, { name: "has_donation", control: control, defaultValue: false, render: function (_a) {
                            var _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, value = _b.value, error = _a.fieldState.error;
                            return (React.createElement(Checkbox, { onChange: function (e) {
                                    toggleHasDonation();
                                    onChange(e);
                                }, 
                                // checked={getValues(
                                //     "has_donation"
                                // )}
                                value: value }));
                        } }) }),
                React.createElement(FormControlLabel, { label: "Include unmarketable members", control: React.createElement(Controller, { name: "unmarketable_status", control: control, defaultValue: false, render: function (_a) {
                            var _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, value = _b.value, error = _a.fieldState.error;
                            return (React.createElement(Checkbox, { onChange: onChange, 
                                // checked={getValues(
                                //     "unmarketable_status"
                                // )}
                                value: value }));
                        } }) }))) : null,
            React.createElement(Grid, { xs: 6, md: 3 },
                React.createElement(GenericControllerCheckboxInput, { name: "cancelled_status", control: control, defaultChecked: false, label: "Include cancelled members", color: "primary" })),
            React.createElement(Grid, { container: true, xs: 12, justifyContent: "center" },
                React.createElement(Grid, { xs: 3 },
                    React.createElement(Button, { type: "button", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true }, "Run Report"))))),
        !showMenu && (React.createElement(Display, { csrf: csrf, summary: summary, formData: form_data, handleToggleMenu: function () {
                toggleShowMenu(!showMenu);
            } })),
        React.createElement(Modal, { open: isSubmitting, onClose: handleCloseModal },
            React.createElement(Dialog, { open: isSubmitting, onClose: handleCloseModal },
                React.createElement(DialogContent, { style: { minWidth: "500px", textAlign: "center" } }, "This form is being submitted")))));
}
