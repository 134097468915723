import React, { useState } from "react";
import Layout from "../Layout";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ReferralReportForm from "./components/ReferralReportForm";
import ReferralReportTable from "../../components/ReferralReportTable";
import PageTitle from "../../components/PageTitle";
import NumberFormat from "react-number-format";
import { format_date } from "../../utils/dateUtils";
import axios from "axios";
export default function ReferralReport(_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    var formData = response_data.form_data, csrf = response_data.csrf;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var download = function (route) {
        axios
            .post(route, {
            _token: csrf,
            formData: JSON.stringify(formData),
        }, { responseType: "blob" })
            .then(function (response) {
            var _a;
            var url = window.URL.createObjectURL(new Blob([response.data]));
            var link = document.createElement("a");
            link.href = url;
            var today = format_date(new Date());
            var fileName = "Referrals_" + today + ".csv";
            link.setAttribute("download", fileName);
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            (_a = link === null || link === void 0 ? void 0 : link.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(link);
        })
            .catch(function () {
            console.error("Error exporting.");
        });
    };
    return (React.createElement(Layout, { title: "Home", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(ReferralReportForm, { data: response_data, serverErrors: serverErrors }),
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: "Summary" })),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 3, mb: 2 },
                React.createElement(Typography, { variant: "h5" }, "Memberships")),
            React.createElement(Grid, { xs: 3, mb: 2 },
                React.createElement(Typography, { variant: "h5" }, "Contributions")),
            React.createElement(Grid, { xs: 6, mb: 2 },
                React.createElement(Typography, { variant: "h5" }, "Membership Terms")),
            React.createElement(Grid, { xs: 3 },
                React.createElement(Grid, { xs: 12, container: true },
                    React.createElement(Grid, { xs: 6 }, "New Members"),
                    React.createElement(Grid, { xs: 6 }, response_data.summary.total_new_members),
                    React.createElement(Grid, { xs: 6 }, "Renewal Members"),
                    React.createElement(Grid, { xs: 6 }, response_data.summary.total_renewal_members),
                    React.createElement(Grid, { xs: 6 }, "Total Member"),
                    React.createElement(Grid, { xs: 6 }, response_data.summary.total_membership),
                    React.createElement(Grid, { xs: 6 }, "Total Income"),
                    React.createElement(Grid, { xs: 6 },
                        React.createElement(NumberFormat, { value: response_data.summary.total_dollars, displayType: "text", decimalScale: 2, fixedDecimalScale: true, thousandSeparator: true, prefix: "$" })),
                    React.createElement(Grid, { xs: 6 }, "Recurring"),
                    React.createElement(Grid, { xs: 6 }, response_data.summary.total_recurring))),
            React.createElement(Grid, { xs: 3, mb: 2 },
                React.createElement(Grid, { xs: 12, container: true },
                    React.createElement(Grid, { xs: 6 }, "New Contributions"),
                    React.createElement(Grid, { xs: 6 }, response_data.summary.total_new_contributions),
                    React.createElement(Grid, { xs: 6 }, "Renewal Contributions"),
                    React.createElement(Grid, { xs: 6 }, response_data.summary.total_renewal_contributions),
                    React.createElement(Grid, { xs: 6 }, "Total Contributions"),
                    React.createElement(Grid, { xs: 6 }, response_data.summary.total_contributions),
                    React.createElement(Grid, { xs: 6 }, "Total Contributions"),
                    React.createElement(Grid, { xs: 6 },
                        React.createElement(NumberFormat, { value: response_data.summary
                                .total_dollar_contributions, displayType: "text", decimalScale: 2, fixedDecimalScale: true, thousandSeparator: true, prefix: "$" })))),
            React.createElement(Grid, { xs: 6, mb: 2 }, response_data.summary.total_by_product
                .sort(function (a, b) { return (a.name > b.name ? 1 : -1); })
                .map(function (el, index) { return (React.createElement(Grid, { container: true, xs: 12, key: "product_".concat(index) },
                React.createElement(Grid, { xs: 6 }, el.name),
                React.createElement(Grid, { xs: 6 }, el.total))); })),
            response_data.summary.total > 0 && (React.createElement(Grid, { container: true, xs: 12, spacing: 1 },
                React.createElement(Grid, { xs: 3 },
                    React.createElement(Button, { type: "submit", variant: "outlined", color: "primary", fullWidth: true, onClick: function () {
                            return download("/referrals-download-quick");
                        } }, "Download Quick Report")),
                React.createElement(Grid, { xs: 3 },
                    React.createElement(Button, { type: "submit", variant: "outlined", color: "primary", fullWidth: true, onClick: function () {
                            return download("/referrals-download-detailed");
                        } }, "Download Detailed Report")),
                React.createElement(Grid, { xs: 3 },
                    React.createElement(Button, { type: "submit", variant: "outlined", color: "primary", fullWidth: true, onClick: function () {
                            return download("/referrals-download-full");
                        } }, "Download Full Report")))),
            React.createElement(Grid, { xs: 12 },
                React.createElement(ReferralReportTable, { data: response_data.summary.total_by_referral, title: "Membership Data" })))));
}
