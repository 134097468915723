import React from "react";
import { BriefcaseIcon, FormPaperIcon, HandshakeIcon, HeartPulseIcon, MoneyIcon, PeopleIcon, PersonIcon, PoliciesIcon, } from "../components/IconLibrary";
import { hasPermission } from "./utils";
export var reportTypes = [
    {
        id: "members",
        name: "Members",
        icon: React.createElement(PeopleIcon, null),
        access: "run member reports",
    },
    {
        id: "orders",
        name: "Orders",
        icon: React.createElement(MoneyIcon, null),
        access: "run order reports",
    },
    {
        id: "leads",
        name: "Leads",
        icon: React.createElement(HandshakeIcon, null),
        access: "run lead reports",
    },
    {
        id: "health_applications",
        name: "Health Applications",
        icon: React.createElement(HeartPulseIcon, null),
        access: "run health_app reports",
    },
    {
        id: "life_applications",
        name: "Life Applications",
        icon: React.createElement(FormPaperIcon, null),
        access: "run life_app reports",
    },
    {
        id: "financial_applications",
        name: "Financial Applications",
        icon: React.createElement(FormPaperIcon, null),
        access: "run financial_app reports",
    },
    {
        id: "policies",
        name: "Policies",
        icon: React.createElement(PoliciesIcon, null),
        access: "run policy reports",
    },
    {
        id: "donations",
        name: "donations",
        icon: React.createElement(PersonIcon, null),
        access: "run donation reports",
    },
    {
        id: "campaigns",
        name: "campaigns",
        icon: React.createElement(BriefcaseIcon, null),
        access: "run campaign reports",
    },
];
export var filterOptions = [
    { id: "lifetime", name: "Lifetime" },
    { id: "mailing_list", name: "Mailing List" },
    { id: "magazine_list", name: "Magazine List" },
    { id: "agents", name: "Agents" },
    { id: "member_since", name: "Member Since" },
    { id: "membership_cancellation", name: "Membership Cancellation" },
    { id: "membership_expiration", name: "Membership Expiration" },
    {
        id: "membership_prev_expiration",
        name: "Membership Previous Expiration",
    },
    { id: "send_cards", name: "Send Cards" },
    { id: "created_date", name: "Created Date" },
    { id: "dob", name: "Birthday" },
    { id: "no_email", name: "No Email" },
    { id: "email", name: "Email" },
    { id: "states", name: "States" },
    { id: "business_type", name: "Has Business Type" },
];
export var statuses = function (auth) {
    var array = [
        {
            id: "active",
            name: "Active",
        },
        {
            id: "pending",
            name: "Pending",
        },
        {
            id: "prospect",
            name: "Prospect",
        },
        { id: "deleted", name: "Deleted" },
    ];
    hasPermission(auth, "run unmarketable_member reports")
        ? array.push({ id: "unmarketable", name: "Unmarketable" })
        : null;
    return array;
};
export var userTypes = [
    {
        id: "App\\Models\\Agent",
        name: "Agents",
    },
    {
        id: "App\\Models\\AbsBroker",
        name: "ABS Agents",
    },
];
export var labelAgentOptions = function (agents) {
    // const labelledAgents =
    return agents.map(function (opt) {
        if (opt.userable_type.includes("AbsBroker") &&
            !opt.name.includes(" (ABS)")) {
            opt.name += " (ABS)";
        }
        return opt;
    });
    // return labelledAgents;
};
export var orderStatuses = [
    { id: "successful", name: "successful" },
    { id: "refunded", name: "refunded" },
    { id: "failed", name: "failed" },
    { id: "deleted", name: "deleted" },
];
export var memberOrderTypes = [
    {
        id: "0",
        name: "New Members",
    },
    {
        id: "1",
        name: "Renewal",
    },
];
export var getFormData = function (form_data) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23;
    return {
        utm_campaign: (_a = form_data === null || form_data === void 0 ? void 0 : form_data.utm_campaign) !== null && _a !== void 0 ? _a : "",
        usaepay_id: (_b = form_data === null || form_data === void 0 ? void 0 : form_data.usaepay_id) !== null && _b !== void 0 ? _b : "",
        find_by_id: (_c = form_data === null || form_data === void 0 ? void 0 : form_data.find_by_id) !== null && _c !== void 0 ? _c : "",
        find_by_member_id: (_d = form_data === null || form_data === void 0 ? void 0 : form_data.find_by_member_id) !== null && _d !== void 0 ? _d : "",
        find_by_zip: (_e = form_data === null || form_data === void 0 ? void 0 : form_data.find_by_zip) !== null && _e !== void 0 ? _e : "",
        find_by_congressional_district: (_f = form_data === null || form_data === void 0 ? void 0 : form_data.find_by_congressional_district) !== null && _f !== void 0 ? _f : "",
        start_date: (_g = form_data === null || form_data === void 0 ? void 0 : form_data.start_date) !== null && _g !== void 0 ? _g : "",
        end_date: (_h = form_data === null || form_data === void 0 ? void 0 : form_data.end_date) !== null && _h !== void 0 ? _h : "",
        card_start_date: (_j = form_data === null || form_data === void 0 ? void 0 : form_data.card_start_date) !== null && _j !== void 0 ? _j : "",
        card_end_date: (_k = form_data === null || form_data === void 0 ? void 0 : form_data.card_end_date) !== null && _k !== void 0 ? _k : "",
        prev_exp_start_date: (_l = form_data === null || form_data === void 0 ? void 0 : form_data.prev_exp_start_date) !== null && _l !== void 0 ? _l : "",
        prev_exp_end_date: (_m = form_data === null || form_data === void 0 ? void 0 : form_data.prev_exp_end_date) !== null && _m !== void 0 ? _m : "",
        exp_start_date: (_o = form_data === null || form_data === void 0 ? void 0 : form_data.exp_start_date) !== null && _o !== void 0 ? _o : "",
        exp_end_date: (_p = form_data === null || form_data === void 0 ? void 0 : form_data.exp_end_date) !== null && _p !== void 0 ? _p : "",
        since_start_date: (_q = form_data === null || form_data === void 0 ? void 0 : form_data.since_start_date) !== null && _q !== void 0 ? _q : "",
        since_end_date: (_r = form_data === null || form_data === void 0 ? void 0 : form_data.since_end_date) !== null && _r !== void 0 ? _r : "",
        dob_start_date: (_s = form_data === null || form_data === void 0 ? void 0 : form_data.dob_start_date) !== null && _s !== void 0 ? _s : "",
        dob_end_date: (_t = form_data === null || form_data === void 0 ? void 0 : form_data.dob_end_date) !== null && _t !== void 0 ? _t : "",
        follow_start_date: (_u = form_data === null || form_data === void 0 ? void 0 : form_data.follow_start_date) !== null && _u !== void 0 ? _u : "",
        follow_end_date: (_v = form_data === null || form_data === void 0 ? void 0 : form_data.follow_end_date) !== null && _v !== void 0 ? _v : "",
        cancel_start_date: (_w = form_data === null || form_data === void 0 ? void 0 : form_data.cancel_start_date) !== null && _w !== void 0 ? _w : "",
        cancel_end_date: (_x = form_data === null || form_data === void 0 ? void 0 : form_data.cancel_end_date) !== null && _x !== void 0 ? _x : "",
        memberOrderType: (_y = form_data === null || form_data === void 0 ? void 0 : form_data.member_order_type) !== null && _y !== void 0 ? _y : Array(),
        has_donation: (_z = form_data === null || form_data === void 0 ? void 0 : form_data.has_donation) !== null && _z !== void 0 ? _z : Array(),
        status: (_0 = form_data === null || form_data === void 0 ? void 0 : form_data.status) !== null && _0 !== void 0 ? _0 : Array(),
        orderStatus: (_1 = form_data === null || form_data === void 0 ? void 0 : form_data.orderStatus) !== null && _1 !== void 0 ? _1 : Array(),
        policyStatus: (_2 = form_data === null || form_data === void 0 ? void 0 : form_data.policyStatus) !== null && _2 !== void 0 ? _2 : Array(),
        report: (_3 = form_data === null || form_data === void 0 ? void 0 : form_data.report) !== null && _3 !== void 0 ? _3 : "default",
        order_types: (_4 = form_data === null || form_data === void 0 ? void 0 : form_data.order_types) !== null && _4 !== void 0 ? _4 : Array(),
        lead_types: (_5 = form_data === null || form_data === void 0 ? void 0 : form_data.lead_types) !== null && _5 !== void 0 ? _5 : Array(),
        insurance_types: (_6 = form_data === null || form_data === void 0 ? void 0 : form_data.insurance_types) !== null && _6 !== void 0 ? _6 : Array(),
        user_types: (_7 = form_data === null || form_data === void 0 ? void 0 : form_data.user_types) !== null && _7 !== void 0 ? _7 : Array(),
        disposition: (_8 = form_data === null || form_data === void 0 ? void 0 : form_data.disposition) !== null && _8 !== void 0 ? _8 : Array(),
        filter_by: (_9 = form_data === null || form_data === void 0 ? void 0 : form_data.filter_by) !== null && _9 !== void 0 ? _9 : Array("created_date"),
        states: (_10 = form_data === null || form_data === void 0 ? void 0 : form_data.states) !== null && _10 !== void 0 ? _10 : Array(),
        agents: (_11 = form_data === null || form_data === void 0 ? void 0 : form_data.agents) !== null && _11 !== void 0 ? _11 : Array(),
        locations: (_12 = form_data === null || form_data === void 0 ? void 0 : form_data.locations) !== null && _12 !== void 0 ? _12 : Array(),
        created_by: (_13 = form_data === null || form_data === void 0 ? void 0 : form_data.created_by) !== null && _13 !== void 0 ? _13 : Array(),
        transferred_to: (_14 = form_data === null || form_data === void 0 ? void 0 : form_data.transferred_to) !== null && _14 !== void 0 ? _14 : Array(),
        referrals: (_15 = form_data === null || form_data === void 0 ? void 0 : form_data.referrals) !== null && _15 !== void 0 ? _15 : Array(),
        utm_campaigns: (_16 = form_data === null || form_data === void 0 ? void 0 : form_data.utm_campaigns) !== null && _16 !== void 0 ? _16 : Array(),
        utm_contents: (_17 = form_data === null || form_data === void 0 ? void 0 : form_data.utm_contents) !== null && _17 !== void 0 ? _17 : Array(),
        utm_objectives: (_18 = form_data === null || form_data === void 0 ? void 0 : form_data.utm_objectives) !== null && _18 !== void 0 ? _18 : Array(),
        utm_mediums: (_19 = form_data === null || form_data === void 0 ? void 0 : form_data.utm_mediums) !== null && _19 !== void 0 ? _19 : Array(),
        utm_sources: (_20 = form_data === null || form_data === void 0 ? void 0 : form_data.utm_sources) !== null && _20 !== void 0 ? _20 : Array(),
        min_donation_amount: (_21 = form_data === null || form_data === void 0 ? void 0 : form_data.min_donation_amount) !== null && _21 !== void 0 ? _21 : "",
        max_donation_amount: (_22 = form_data === null || form_data === void 0 ? void 0 : form_data.max_donation_amount) !== null && _22 !== void 0 ? _22 : "",
        show_latest_order: (_23 = form_data === null || form_data === void 0 ? void 0 : form_data.show_latest_order) !== null && _23 !== void 0 ? _23 : null,
    };
};
export var policyStatuses = [
    { id: "approved", name: "approved" },
    { id: "Cancelled", name: "Cancelled" },
    { id: "Declined", name: "Declined" },
    { id: "Enrolled into a new plan ", name: "Enrolled into a new plan " },
    {
        id: "Enrolled into a new plan - See new policy",
        name: "Enrolled into a new plan - See new policy",
    },
    { id: "in_process", name: "in_process" },
    { id: "Issued", name: "Issued" },
    { id: "Issued Everest Florida", name: "Issued Everest Florida" },
    { id: "rejected", name: "rejected" },
    { id: "Renewal", name: "Renewal" },
    { id: "Underwriting", name: "Underwriting" },
    { id: "Withdrawn", name: "Withdrawn" },
];
