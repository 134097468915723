import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Typography, Paper, Button, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import Layout from "../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import GenericControllerTextInput from "../../components/GenericFormFields/TextInput";
import GenericPhoneInput from "../../components/GenericFormFields/GenericPhoneInput";
import Address from "../../components/Address";
import { brokerAddressOptions } from "../../utils/utils";
import BrokerInfo from "./components/BrokerInfo";
import BrokerDocument from "./components/BrokerDocument";
import BrokerQuestions from "./components/BrokerQuestions";
import GenericRadioInput from "../../components/GenericFormFields/GenericRadioInput";
import GenericControllerDropdownInput from "../../components/GenericFormFields/DropdownInput";
import { router, Link } from "@inertiajs/react";
import route from "ziggy-js";
var AbsForm = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _o = useState(true), menuOpen = _o[0], toggleMenuOpen = _o[1];
    var _p = useForm(), handleSubmit = _p.handleSubmit, errors = _p.formState.errors, control = _p.control, watch = _p.watch;
    var broker = response_data.broker, user = response_data.user, states = response_data.states, onboarding_statuses = response_data.onboarding_statuses, amac_recruiters = response_data.amac_recruiters;
    var _q = useState((_b = broker === null || broker === void 0 ? void 0 : broker.referral_id) !== null && _b !== void 0 ? _b : ""), referral = _q[0], setReferral = _q[1];
    var referrals = [
        { id: 152, name: "Email" },
        { id: 252, name: "Facebook" },
        { id: 505, name: "Facebook-Twitter" },
        { id: 834, name: "Twitter" },
        { id: 1515, name: "Mailing" },
        { id: 2290, name: "AMAC Member" },
        { id: 2291, name: "Agent Referral" },
        { id: 2292, name: "Outbound Call" },
        { id: 2293, name: "LinkedIn" },
        { id: 2761, name: "Telemarketer" },
        { id: 2762, name: "Indeed" },
        { id: 2293, name: "Job Posting" },
    ];
    var onSubmit = function (data) {
        if (user === null || user === void 0 ? void 0 : user.id) {
            data.user_id = user.id;
            router.post("/user/abs/update", data);
        }
        else {
            router.post("/user/abs/store", data);
        }
    };
    return (React.createElement(Layout, { title: "ABS Broker", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { container: true, direction: "column", xs: 12, spacing: 4 },
                !isEmpty(serverErrors) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h4", component: "h3", align: "center" }, (user === null || user === void 0 ? void 0 : user.id) ? "Edit ABS Broker" : "New ABS Broker")),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                            React.createElement(Grid, { xs: 12 },
                                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Contact Information")),
                            React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                                React.createElement(Grid, { xs: 12, md: 4 },
                                    React.createElement(GenericControllerTextInput, { name: "user[first_name]", label: "First Name", control: control, defaultValue: (_c = user === null || user === void 0 ? void 0 : user.first_name) !== null && _c !== void 0 ? _c : "", rules: { required: true }, error: errors.user &&
                                            "first_name" in errors.user, autoFocus: true })),
                                React.createElement(Grid, { xs: 12, md: 4 },
                                    React.createElement(GenericControllerTextInput, { name: "user[last_name]", label: "Last Name", control: control, defaultValue: (_d = user === null || user === void 0 ? void 0 : user.last_name) !== null && _d !== void 0 ? _d : "", rules: { required: true }, error: errors.user &&
                                            "last_name" in errors.user })),
                                React.createElement(Grid, { xs: 12, md: 4 },
                                    React.createElement(GenericControllerTextInput, { type: "date", label: "Birth Date", name: "broker[birth_date]", control: control, defaultValue: (_e = broker === null || broker === void 0 ? void 0 : broker.birth_date) !== null && _e !== void 0 ? _e : "", error: errors.broker &&
                                            "birth_date" in errors.broker, shrinkLabel: true })),
                                React.createElement(Grid, { xs: 12, md: 6 },
                                    React.createElement(GenericControllerTextInput, { name: "broker[business_name]", label: "Business Name", control: control, defaultValue: (_f = broker === null || broker === void 0 ? void 0 : broker.business_name) !== null && _f !== void 0 ? _f : "", error: errors.broker &&
                                            "business_name" in errors.broker })),
                                React.createElement(Grid, { xs: 12, md: 6 },
                                    React.createElement(GenericControllerTextInput, { name: "user[email]", label: "Email", control: control, defaultValue: (_g = user === null || user === void 0 ? void 0 : user.email) !== null && _g !== void 0 ? _g : "", rules: { required: true }, error: errors.user &&
                                            "email" in errors.user })),
                                React.createElement(Grid, { xs: 12, md: 6 },
                                    React.createElement(GenericPhoneInput, { name: "broker[phone]", label: "Phone", defaultValue: (_h = broker === null || broker === void 0 ? void 0 : broker.phone) !== null && _h !== void 0 ? _h : "", control: control, helperText: "Please provide full phone (###) ###-####.", required: true })),
                                React.createElement(Grid, { xs: 12, md: 6 },
                                    React.createElement(GenericPhoneInput, { label: "Alt. Phone", defaultValue: (_j = broker === null || broker === void 0 ? void 0 : broker.phone_alt) !== null && _j !== void 0 ? _j : "", name: "broker[phone_alt]", control: control, required: false, helperText: "Please provide full phone (###) ###-####." })))))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                            React.createElement(Address, { data: broker, control: control, errors: errors, states: states, options: brokerAddressOptions })))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                            React.createElement(BrokerInfo, { broker: broker, user: user, onboarding_statuses: onboarding_statuses, control: control, errors: {
                                    error: { errors: errors },
                                }, states: states })))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                            React.createElement(BrokerDocument, { data: broker, control: control, errors: {
                                    error: { errors: errors },
                                } })))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                            React.createElement(BrokerQuestions, { data: broker, control: control, errors: {
                                    error: { errors: errors },
                                } })))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                            React.createElement(Grid, { xs: 12 },
                                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Broker Questions Continued...")),
                            React.createElement(Grid, { xs: 12 },
                                React.createElement(GenericControllerTextInput, { name: "broker[years_in_business]", label: "How many years have you been conducting business in the insurance marketplace?", control: control, defaultValue: (_k = broker === null || broker === void 0 ? void 0 : broker.years_in_business) !== null && _k !== void 0 ? _k : "", error: errors.broker &&
                                        "years_in_business" in errors.broker })),
                            React.createElement(Grid, { xs: 12, md: 6 },
                                React.createElement(GenericRadioInput, { defaultValue: (_l = broker === null || broker === void 0 ? void 0 : broker.experience) !== null && _l !== void 0 ? _l : "", control: control, rules: { required: true }, name: "broker[experience]", legend: "Do you have experience working in the Medicare marketplace?", 
                                    // errors={{"error": {"errors": errors}}}
                                    error: errors.broker &&
                                        "experience" in errors.broker
                                        ? true
                                        : false })),
                            React.createElement(Grid, { xs: 12, md: 6 },
                                React.createElement(GenericControllerDropdownInput, { label: "Referral", name: "broker[referral_id]", id: "referral_select", defaultValue: referral, handleChange: function (e) {
                                        return setReferral(e.target.value);
                                    }, rules: {
                                        required: true,
                                    }, error: errors.broker &&
                                        "referral_id" in errors.broker, control: control, options: !!(referrals === null || referrals === void 0 ? void 0 : referrals.length) &&
                                        referrals.map(function (referral) { return (React.createElement(MenuItem, { key: referral.id, value: referral.id }, referral.name)); }) })),
                            React.createElement(Grid, { xs: 12, md: 6 },
                                React.createElement(GenericControllerDropdownInput, { label: "Recruiter", name: "broker[amac_recruiter_id]", id: "recruiter_select", defaultValue: (_m = broker === null || broker === void 0 ? void 0 : broker.amac_recruiter_id) !== null && _m !== void 0 ? _m : "", handleChange: function (e) {
                                        return setReferral(e.target.value);
                                    }, rules: {
                                        required: false,
                                    }, error: errors.broker &&
                                        "recruiter_id" in errors.broker, control: control, options: amac_recruiters.map(function (recruiter) { return (React.createElement(MenuItem, { key: recruiter.id, value: recruiter.id }, recruiter.name)); }) }))))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                            React.createElement(Grid, { xs: 12 },
                                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Comments and Notes")),
                            React.createElement(GenericControllerTextInput, { control: control, label: "Broker Note", multiline: true, type: "text", defaultValue: "", name: "notes", error: false, minRows: 5 })))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                            React.createElement(Grid, { xs: 12, container: true, alignItems: "center", justifyContent: "center", spacing: 2 },
                                React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                                    React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: route("show_broker", user.id).toString() }, "Cancel")),
                                React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                                    React.createElement(Button, { type: "button", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true }, (broker === null || broker === void 0 ? void 0 : broker.id) != null
                                        ? "Update Broker"
                                        : "Add Broker"))))))))));
};
export default AbsForm;
