import { Box, Paper, TableContainer, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import PageTitle from "../components/PageTitle";
var ReferralReportTable = function (_a) {
    var data = _a.data, title = _a.title;
    var rows = [];
    data.map(function (referral, index) {
        var values = {
            id: index,
            source: referral.source,
            medium: referral.medium,
            campaign: referral.name,
            content: referral.content,
            offer: referral.offer,
            recurring: Number(referral.recurring),
            new: Number(referral.new),
            renewal: Number(referral.renewal),
            total: Number(referral.total),
            amount: Number(referral.amount),
        };
        rows.push(values);
    });
    var columns = [
        {
            field: "source",
            headerName: "Source",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.source },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.source))); },
        },
        {
            field: "campaign",
            headerName: "Campaign",
            editable: false,
            flex: 2,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.campaign },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.campaign))); },
        },
        {
            field: "medium",
            headerName: "Medium",
            editable: false,
            flex: 2,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.medium },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.medium))); },
        },
        {
            field: "content",
            headerName: "Content",
            editable: false,
            flex: 2,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.content },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.content))); },
        },
        {
            field: "offer",
            headerName: "Offer",
            editable: false,
            flex: 2,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.offer },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.offer))); },
        },
        {
            field: "recurring",
            headerName: "Recurring",
            headerAlign: "right",
            align: "right",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.recurring },
                React.createElement("span", { className: "table-cell-trucate" }, parseInt(rowValues.row.recurring).toLocaleString("en-US")))); },
        },
        {
            field: "new",
            headerName: "N",
            headerAlign: "right",
            align: "right",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.new },
                React.createElement("span", { className: "table-cell-trucate" }, parseInt(rowValues.row.new).toLocaleString("en-US")))); },
        },
        {
            field: "renewal",
            headerName: "R",
            headerAlign: "right",
            align: "right",
            editable: false,
            flex: 1,
            width: 50,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.renewal },
                React.createElement("span", { className: "table-cell-trucate" }, parseInt(rowValues.row.renewal).toLocaleString("en-US")))); },
        },
        {
            field: "total",
            headerName: "T",
            headerAlign: "right",
            align: "right",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.total },
                React.createElement("span", { className: "table-cell-trucate" }, parseInt(rowValues.row.total).toLocaleString("en-US")))); },
        },
        {
            field: "amount",
            headerName: "Amount",
            headerAlign: "right",
            align: "right",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.amount },
                React.createElement("span", { className: "table-cell-trucate" }, "$".concat(parseInt(rowValues.row.amount).toLocaleString("en-US"))))); },
        },
    ];
    return (React.createElement(Box, { mt: 2 },
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: title })),
        React.createElement(TableContainer, { component: Paper, elevation: 3 },
            React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: 100, autoHeight: true, rowsPerPageOptions: [100] }))));
};
export default ReferralReportTable;
